import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import toast from "react-hot-toast"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleReactValidator from "simple-react-validator"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import API from '../api/postings'

import formPhotoPlaceholder from "../../static/assets/images/upload-img.png"

import Thankyou from '../components/addon/thankYou'
import APIButton from "../components/addon/button/apiButton"
import UseCustomValidator from "../service/simpleValidator"

import useScript from "../service/useScript"
import useDeviceDetect from "../service/useDeviceDetect"
import { acceptOnlyNumbers } from "../service/helper"

const OnlineAdmissionForm = () => {

    const [loading, setLoading] = useState(false)
    const [thankYou, setThankYou] = useState(false)
    const [checkbox, setCheckbox] = useState(null);
    // const { customMobileValidator } = UseCustomValidator()
    // const validator = customMobileValidator
    const [, forceUpdateForRegister] = useState();
    const [validator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" >{message}</p>}
    )))

    const ref = useRef();

    const payload = {
        webform_id : "online_admission_form_for_aihs",
        course_1 : '',
        course_2 : '',

        name_of_the_applicant : '',
        date_of_birth : '',
        age : '',
        religion : '',
        caste_category : '',

        nationality : '',
        aadhar_card_no_ : '',
        pan_card_voter_id_no_ : '',
        contact_no_ : '',
        email_address : '',

        address_for_communication:'',
        pin_code :'',
        permanent_communication : '',
        pin_code2 : '',

        fathers_name : '',
        fathers_qualification: "",
        fathers_occupation: '',
        fathers_name_address_of_workplace:'',
        fathers_phone_no_:'',
        fathers_email_id:'',
        fathers_annual_income:'',

        signature_of_father:'',

        mothers_name : '',
        mothers_qualification: "",
        mothers_occupation: '',
        mothers_name_address_of_workplace_:'',
        mothers_phone_no_:'',
        mothers_email_id:'',
        mothers_annual_income:'',

        signature_of_mother_guardian : '',

        ssc :'',
        subjects_studied :'',
        month_year_of_passing :'',
        board_name:'',
        marks_obtained :'',
        max_marks :'',
        overall_percent :'',

        hsc :'',
        subjects_studied2 :'',
        month_year_of_passing2 :'',
        collage_university_name:'',
        marks_obtained2 :'',
        max_marks2 :'',
        overall_percent2 :'',

        bsc :'',
        subjects_studied3 :'',
        month_year_of_passing3 :'',
        collage_university_name2:'',
        marks_obtained3 :'',
        max_marks3 :'',
        overall_percent3 :'',

        bsc4 :'',
        subjects_studied4 :'',
        month_year_of_passing4 :'',
        collage_university_name4:'',
        marks_obtained4 :'',
        max_marks4 :'',
        overall_percent4 :'',

        organization_name : '',
        designation : '',
        from_date : '',
        to_date : '',
        job_role : '',

        dob : '',
        dobFrm :'',
        dobTo :'',
    }

    const [form, setForm] = useState(payload)
    const [photo, setPhoto] = useState(formPhotoPlaceholder)
    const [fileError, setFileError] = useState('')
    const [profileImg, setProfileImg] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = validator.current.allValid()
        if (result === false) {
            validator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(result) {
            const res = await API.submitImage(profileImg)
            .catch(err => {
                console.log(err)
                toast.error("Profile Image Upload Failed.")
                return
            })
            if(res && res.fid && res.fid.length > 0){
                let id = res.fid[0].value.toString();
                const response = await API.submitFormData( {...form, profile_photo: id } )
                .catch(err => {
                    console.log(err)
                    toast.error("Looks like something went wrong. Please try again later.")
                })
                if (response && checkbox) {
                    setThankYou(true);
                    // resetForm();
                    // localStorage.removeItem(lockey);
                    // localStorage.removeItem(emailkey);
                    window.scroll({ top: 0, behavior: "smooth" })
                }else{
                    toast.error("Please accept DECLARATION before proceeding.")
                }
            }
        } else {
            toast.error("Looks like something went wrong. Please try again later.")
        }
    }

    function calculateAge(dateString) {
        const from = dateString.split("/");
        const birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
        const cur = new Date();
        const diff = cur - birthdateTimeStamp;
        // This is the difference in milliseconds
        const currentAge = Math.floor(diff/31557600000);
        return currentAge;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === 'age' || name === 'cell_phone_number' || name === 'pincode') {
            let val =  acceptOnlyNumbers(value)
            setForm({
                ...form,
                [name]: val,
            });
        } else {
            setForm({
                ...form,
                [name]: value,
            });
        }
    };
    console.log('ppppppppppppp', form)
    const handleImageUpload = (e) => {
        let fileName =  e.target.value.toLowerCase()
        let file = e.target.files[0]
        if (file && fileName.includes('.jpg') 
            || file && fileName.includes('.png')
            || file && fileName.includes('.jpeg')) {
            setFileError("");
            setPhoto(URL.createObjectURL(file))
            setProfileImg(file)
        } else {
            setFileError("Please upload your Photo in .jpg or .png format")
            ref.current.value = "";
        }
    }

    const removeUpload = (e) => {
        setPhoto(formPhotoPlaceholder)
        ref.current.value = "";
        setProfileImg(null)
    }

    const resetThankyou = (value) => {
        if(window) window.location.reload();
    }
    useScript("/assets/js/uploadReport.js")
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/accessibility.css",
                            "/assets/css/admission-form.css",
                        ],
                    }}
                />
                {thankYou ? <Thankyou setThankYou={resetThankyou} /> : (
                    <>
                        <section class="breadcrumbs">
                            <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul>
                                        <li><a href="javascript:;">Home</a></li>
                                        <li><span>Application Form</span></li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </section>
                        <main class="innerpage">
                            <section class="section_bg login_section pt-first-section">
                                <div class="container">
                                    <h2 class="section-heading">Application Form</h2>
                                    <div class="row no-gutters">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="white_rounded_box">
                                                <div class="applicationFormsSec">
                                                    <div class="form_wrap">
                                                        <div class="topTitleHeading">
                                                            <h2>P. D. Hinduja Hospital
                                                                <span class="smallSubTitle">& Medical Research Center</span>
                                                                <span class="bigSubTitle">(Skill Development Center)</span>
                                                            </h2>
                                                            <p>Affiliated By Maharashtra State Board Of Skill Vocational, Education & Training (MSBSVET*)</p>
                                                        </div>  
                                                        <div class="midSection">
                                                            <div class="rightSec">
                                                                <span class="">Form No. : 2024-25</span>
                                                            </div> 
                                                            <div class="leftSec">   
                                                                <h3>Application Form for Full Time / Part Time
                                                                <span>Allied Health Science program</span>
                                                                </h3>
                                                            </div>
                                                        </div>   
                                                        <div class="bottomSection">
                                                            <p>
                                                                <strong>(Form to be filled by Applicant in CAPITAL letter)</strong><br/>
                                                                <strong>Course Type : Full time(FT) / Part Time(PT)</strong> (for working applicants with relevant exp.)  
                                                            </p>
                                                        </div>
                                                        <div class="form_block pl-0">
                                                            <div class="row flex-row-reverse">
                                                                <div class="col-md-4 col-lg-3 col-5">
                                                                    <div class="file-upload form-group">
                                                                        <span class="imgSec d-block mb-3">
                                                                            <img src={photo} alt="form Photo Placeholder" />
                                                                            {/* <button class="file-upload-btn" type="button" onclick="$('.file-upload-input').trigger( 'click' )">Add Image</button> */}
                                                                            <div className="input-group uploadImgsec">
                                                                                <div className="input-group-btn"> 
                                                                                    <span className="fileUpload">
                                                                                        <input type="file" onChange={handleImageUpload} ref={ref} className="upload up" id="up" />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <div class="image-upload-wrap">
                                                                            <input class="file-upload-input" type='file' accept="image/*" />
                                                                            <div class="drag-text">
                                                                                <h3>Drag and drop a file or select add Image</h3>
                                                                            </div>
                                                                        </div>
                                                                        {profileImg && <div class="file-upload-content">
                                                                            <div class="image-title-wrap">
                                                                                <button type="button" onClick={removeUpload} class="remove-image">Remove Image</button>
                                                                            </div>
                                                                        </div>}
                                                                        {validator.current.message('IdentificationProofImage', profileImg, 'required')}
                                                                        {fileError.length > 0 ? <p className="field_error">{fileError}</p> : ""}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <h5>Application form for provisional admission to the course in order of priority:</h5>
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="form-group -animated">
                                                                                <label>1.</label>
                                                                                <input onChange={handleInputChange} class="form-control" name="course_1" value={form.course_1} type="text" />
                                                                                {validator.current.message('course_1', form.course_1, 'required')}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group -animated">
                                                                                <label>2.</label>
                                                                                <input onChange={handleInputChange} class="form-control" name="course_2" value={form.course_2} type="text" />
                                                                                {validator.current.message('course_2', form.course_2, 'required')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="form-group -animated">
                                                                        <label>Name of the applicant</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="name_of_the_applicant" value={form.name_of_the_applicant} type="text" />
                                                                        {validator.current.message('name_of_the_applicant', form.name_of_the_applicant, 'required|alpha_space')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <div class={`form-group -animated ${form.dob === '' ? '' : '-active'}`}>
                                                                        <label>Date of Birth</label>
                                                                        <DatePicker 
                                                                            className="form-control"
                                                                            selected={form?.dob}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            maxDate={new Date()}
                                                                            onChange={async (date) => {
                                                                                if(date) {
                                                                                    let dob = `${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0'+(Number(date.getMonth())+1) : Number(date.getMonth())+1}/${date.getFullYear()}`
                                                                                    let age = await calculateAge(dob)
                                                                                    if(dob?.length) {
                                                                                        setForm({
                                                                                            ...form,
                                                                                            ['date_of_birth']: dob,
                                                                                            ['age']: age,
                                                                                            ['dob']: date
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }} 
                                                                        />
                                                                        {validator.current.message('dob', form.dob, 'required')}
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class={`form-group -animated ${form.age === '' ? '' : '-active'}`}>
                                                                        <label>Age</label>
                                                                        <input class="form-control" value={form.age} type="text" disabled="disabled" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <div class="form-group -animated">
                                                                        <label>Religion</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="religion" value={form.religion} type="text" />
                                                                        {validator.current.message('religion', form.religion, 'required|alpha_space')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <div class="form-group -animated">
                                                                        <label>Caste category</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="caste_category" value={form.caste_category} type="text" />
                                                                        {validator.current.message('caste_category', form.caste_category, 'required|alpha_space')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group -animated">
                                                                        <label>Nationality</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="nationality" value={form.nationality} type="text" />
                                                                        {validator.current.message('nationality', form.nationality, 'required|alpha_space')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group -animated">
                                                                        <label>Aadhar Card No.</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="aadhar_card_no_" value={form.aadhar_card_no_} type="text" />
                                                                        {validator.current.message('aadhar_card_no_', form.aadhar_card_no_, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group -animated">
                                                                        <label>Pan Card/ Voter Id No.</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="pan_card_voter_id_no_" value={form.pan_card_voter_id_no_} type="text" />
                                                                        {validator.current.message('pan_card_voter_id_no_', form.pan_card_voter_id_no_, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Contact No.</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="contact_no_" value={form.contact_no_} type="text" />
                                                                        {validator.current.message('contact_no_', form.contact_no_, 'required|phone')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Email Address</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="email_address" value={form.email_address} type="text" />
                                                                        {validator.current.message('email_address', form.email_address, 'required|email|checkAltEmail')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="form_block pl-0">
                                                            <p class="mb-0"><strong>Address:</strong></p>
                                                            <div class="row">
                                                                <div class="col-lg-8 col-md-8">
                                                                    <div class="form-group -animated">
                                                                        <label>Address for communication</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="address_for_communication" value={form.address_for_communication} type="text" />
                                                                        {validator.current.message('address_for_communication', form.address_for_communication, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                    <div class="form-group -animated">
                                                                        <label>Pin code</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="pin_code" value={form.pin_code} type="text" />
                                                                        {validator.current.message('pin_code', form.pin_code, 'required|numeric')}
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-8 col-md-8">
                                                                    <div class="form-group -animated">
                                                                        <label>Permanent communication</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="permanent_communication" value={form.permanent_communication} type="text" />
                                                                        {validator.current.message('permanent_communication', form.permanent_communication, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                    <div class="form-group -animated">
                                                                        <label>Pin code</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="pin_code2" value={form.pin_code2} type="text" />
                                                                        {validator.current.message('pin_code2', form.pin_code2, 'required|numeric')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form_block pl-0">
                                                            <p class="mb-0"><strong>Details of Parents/Guardian:</strong></p>
                                                            <p class="mb-0"><span>Father</span></p>
                                                            <div class="row pb-4">
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Name:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="fathers_name" value={form.fathers_name} type="text" />
                                                                        {validator.current.message('fathers_name', form.fathers_name, 'required|alpha_space')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Qualification:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="fathers_qualification" value={form.fathers_qualification} type="text" />
                                                                        {validator.current.message('fathers_qualification', form.fathers_qualification, 'required')}
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Occupation:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="fathers_occupation" value={form.fathers_occupation} type="text" />
                                                                        {validator.current.message('fathers_occupation', form.fathers_occupation, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Name & Address of workplace:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="fathers_name_address_of_workplace" value={form.fathers_name_address_of_workplace} type="text" />
                                                                        {validator.current.message('fathers_name_address_of_workplace', form.fathers_name_address_of_workplace, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Phone No.</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="fathers_phone_no_" value={form.fathers_phone_no_} type="text" />
                                                                        {validator.current.message('fathers_phone_no_', form.fathers_phone_no_, 'required|phone')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Email Id</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="fathers_email_id" value={form.fathers_email_id} type="text" />
                                                                        {validator.current.message('fathers_email_id', form.fathers_email_id, 'required|email|checkAltEmail')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Annual Income</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="fathers_annual_income" value={form.fathers_annual_income} type="text" />
                                                                        {validator.current.message('fathers_annual_income', form.fathers_annual_income, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <p class="mb-0"><span>MOTHER/GUARDIAN</span></p>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Name:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="mothers_name" value={form.mothers_name} type="text" />
                                                                        {validator.current.message('mothers_name', form.mothers_name, 'required|alpha_space')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Qualification:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="mothers_qualification" value={form.mothers_qualification} type="text" />
                                                                        {validator.current.message('mothers_qualification', form.mothers_qualification, 'required')}
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Occupation:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="mothers_occupation" value={form.mothers_occupation} type="text" />
                                                                        {validator.current.message('mothers_occupation', form.mothers_occupation, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Name & Address of workplace:</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="mothers_name_address_of_workplace_" value={form.mothers_name_address_of_workplace_} type="text" />
                                                                        {validator.current.message('mothers_name_address_of_workplace_', form.mothers_name_address_of_workplace_, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Phone No.</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="mothers_phone_no_" value={form.mothers_phone_no_} type="text" />
                                                                        {validator.current.message('mothers_phone_no_', form.mothers_phone_no_, 'required|phone')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Email Id</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="mothers_email_id" value={form.mothers_email_id} type="text" />
                                                                        {validator.current.message('mothers_email_id', form.mothers_email_id, 'required|email|checkAltEmail')}
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Annual Income</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="mothers_annual_income" value={form.mothers_annual_income} type="text" />
                                                                        {validator.current.message('mothers_annual_income', form.mothers_annual_income, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form_block pl-0">
                                                            <p class="mb-0"><strong>Educational Qualification Details:</strong></p>

                                                            <div class="educationDetailsSec pb-5">
                                                                <p class="mb-0"><span>School Details</span></p>
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-8">
                                                                        <div class="form-group -animated">
                                                                            <label>S.S.C.</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="ssc" value={form.ssc} type="text" />
                                                                            {validator.current.message('ssc', form.ssc, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Subjects studied</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="subjects_studied" value={form.subjects_studied} type="text" />
                                                                            {validator.current.message('subjects_studied', form.subjects_studied, 'required')}
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Month & year of passing</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="month_year_of_passing" value={form.month_year_of_passing} type="text" />
                                                                            {validator.current.message('month_year_of_passing', form.month_year_of_passing, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Board Name </label>
                                                                            <input onChange={handleInputChange} class="form-control" name="board_name" value={form.board_name} type="text" />
                                                                            {validator.current.message('board_name', form.board_name, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Marks Obtained</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="marks_obtained" value={form.marks_obtained} type="text" />
                                                                            {validator.current.message('marks_obtained', form.marks_obtained, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Max Marks</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="max_marks" value={form.max_marks} type="text" />
                                                                            {validator.current.message('max_marks', form.max_marks, 'required|numeric')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Overall %</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="overall_percent" value={form.overall_percent} type="text" />
                                                                            {validator.current.message('overall_percent', form.overall_percent, 'required|numeric')}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="educationDetailsSec pb-5">
                                                                <p class="mb-0"><span>Higher Educational Details</span></p>
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-8">
                                                                        <div class="form-group -animated">
                                                                            <label>H.S.C.</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="hsc" value={form.hsc} type="text" />
                                                                            {validator.current.message('hsc', form.hsc, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Subjects studied</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="subjects_studied2" value={form.subjects_studied2} type="text" />
                                                                            {validator.current.message('subjects_studied2', form.subjects_studied2, 'required')}
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Month & year of passing</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="month_year_of_passing2" value={form.month_year_of_passing2} type="text" />
                                                                            {validator.current.message('month_year_of_passing2', form.month_year_of_passing2, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>College / University Name</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="collage_university_name" value={form.collage_university_name} type="text" />
                                                                            {validator.current.message('collage_university_name', form.collage_university_name, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Marks Obtained</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="marks_obtained2" value={form.marks_obtained2} type="text" />
                                                                            {validator.current.message('marks_obtained2', form.marks_obtained2, 'required')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Max Marks</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="max_marks2" value={form.max_marks2} type="text" />
                                                                            {validator.current.message('max_marks2', form.max_marks2, 'required|numeric')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Overall %</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="overall_percent2" value={form.overall_percent2} type="text" />
                                                                            {validator.current.message('overall_percent2', form.overall_percent2, 'required|numeric')}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="educationDetailsSec pb-5">
                                                                <p class="mb-0"><span>Degree Details</span></p>
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-8">
                                                                        <div class="form-group -animated">
                                                                            <label>B.SC.</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="bsc" value={form.bsc} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Subjects studied</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="subjects_studied3" value={form.subjects_studied3} type="text" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Month & year of passing</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="month_year_of_passing3" value={form.month_year_of_passing3} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>College / University Name</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="collage_university_name2" value={form.collage_university_name2} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Marks Obtained</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="marks_obtained3" value={form.marks_obtained3} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Max Marks</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="max_marks3" value={form.max_marks3} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Overall %</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="overall_percent3" value={form.overall_percent3} type="text" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="educationDetailsSec pb-5">
                                                                <p class="mb-0"><span>Other Certificates</span></p>
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-8">
                                                                        <div class="form-group -animated">
                                                                            <label>B.SC.</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="bsc4" value={form.bsc4} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Subjects studied</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="subjects_studied4" value={form.subjects_studied4} type="text" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Month & year of passing</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="month_year_of_passing4" value={form.month_year_of_passing4} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>College / University Name</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="collage_university_name4" value={form.collage_university_name4} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Marks Obtained</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="marks_obtained4" value={form.marks_obtained4} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Max Marks</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="max_marks4" value={form.max_marks4} type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6">
                                                                        <div class="form-group -animated">
                                                                            <label>Overall %</label>
                                                                            <input onChange={handleInputChange} class="form-control" name="overall_percent4" value={form.overall_percent4} type="text" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form_block pl-0">
                                                            <p class="mb-0"><strong>Work Experience details:</strong></p>
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-8">
                                                                    <div class="form-group -animated">
                                                                        <label>Organisation name</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="organization_name" value={form.organization_name} type="text" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Designation</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="designation" value={form.designation} type="text" />
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-4 col-md-6">
                                                                    <div class={`form-group -animated ${form.dobFrm === '' ? '' : '-active'}`}>
                                                                        <label>From(dd/mm/yyyy)</label>
                                                                        <DatePicker 
                                                                            className="form-control"
                                                                            selected={form?.dobFrm}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            maxDate={new Date()}
                                                                            onChange={async (date) => {
                                                                                if(date) {
                                                                                    let dobFrom = `${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0'+(Number(date.getMonth())+1) : Number(date.getMonth())+1}/${date.getFullYear()}`
                                                                                    if(dobFrom?.length) {
                                                                                        setForm({
                                                                                            ...form,
                                                                                            ['from_date']: dobFrom,
                                                                                            ['dobFrm']: date
                                                                                        });
                                                                                    }
                                                                                    console.log('dobFrom',form)
                                                                                }
                                                                            }} 
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-6">
                                                                    <div class={`form-group -animated ${form.dobTo === '' ? '' : '-active'}`}>
                                                                        <label>To(dd/mm/yyyy)</label>
                                                                        <DatePicker 
                                                                            className="form-control"
                                                                            selected={form?.dobTo}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            maxDate={new Date()}
                                                                            onChange={async (date) => {
                                                                                if(date) {
                                                                                    let dobTod = `${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0'+(Number(date.getMonth())+1) : Number(date.getMonth())+1}/${date.getFullYear()}`
                                                                                    if(dobTod?.length) {
                                                                                        setForm({
                                                                                            ...form,
                                                                                            ['to_date']: dobTod,
                                                                                            ['dobTo']: date
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }} 
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-6">
                                                                    <div class="form-group -animated">
                                                                        <label>Job Role</label>
                                                                        <input onChange={handleInputChange} class="form-control" name="job_role" value={form.job_role} type="text" />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="form_block pl-0">
                                                            <div class="table-price-wrap table-price-wrap-orange pb-3">
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                    <tbody>
                                                                        <tr style={{background:"#ff9836", color:"#fff"}}>
                                                                            <td colspan="7"><strong>ADMISSION PROCEDURE : Candidates should submit the following documents with duly filled application form</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" colspan="2">3 passport size photographs</td>
                                                                            <td align="right" colspan="3">School Leaving</td>
                                                                            <td align="right" colspan="2">Aadhar card</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" colspan="2">X<sup>th</sup> onwards all certificates and<br />
                                                                                mark sheets (attested)</td>
                                                                            <td align="right" colspan="3">Caste certificate, Domicile, Birth Certificate, Cancelled cheque of your bank account</td>
                                                                            <td align="right" colspan="2">Pan Card/Voter Id</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" colspan="3"><strong>(For Office Use)<br />
                                                                                Document Collected by</strong></td>
                                                                            <td align="left" colspan="4"><strong>Verified by</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" colspan="3">&nbsp;</td>
                                                                            <td align="left" colspan="4">&nbsp;</td>
                                                                        </tr>
                                                                        <tr style={{background:"#ff9836", color:"#fff"}}>
                                                                            <td colspan="7"><strong>FEES STRUCTURE: Fees can be paid in three instalments.Payment Details(DD/Cheque no.)</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" valign="top" colspan="2"><strong>Total Fees<sup>*</sup></strong> (non- refundable)</td>
                                                                            <td align="left" colspan="3" valign="top"><span class="webrupee">`</span>200 at the time of form submission<br />
                                                                                <span class="webrupee">`</span>49000 + GST for six months courses<br />
                                                                                <span class="webrupee">`</span>65000 + GST Tax for one year courses<br />
                                                                                <span class="webrupee">`</span>98000 + GST Tax for two year courses<br /></td>
                                                                            <td align="left" colspan="2" valign="top"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" valign="top" colspan="2"><strong>1st instalment </strong> : (at the time of admission + Post dated cheques for 2nd and 3rd installments)</td>
                                                                            <td align="left" colspan="3" valign="top"><strong>On Admission</strong><br />
                                                                                <span class="webrupee">`</span>25000 + GST for six months courses<br />
                                                                                <span class="webrupee">`</span>25000 + GST Tax for one year courses<br />
                                                                                <span class="webrupee">`</span>40000 + GST Tax for two year courses<br /></td>
                                                                            <td align="left" colspan="2" valign="top"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" valign="top" colspan="2"><strong>2nd instalment </strong> : (b/w1stSep. & 15thNov. after commencement of the course)</td>
                                                                            <td align="left" colspan="3" valign="top"><strong>At the end of Ist month</strong><br />
                                                                                <span class="webrupee">`</span>24000 + GST Tax for six months courses<br />
                                                                                <span class="webrupee">`</span>25000 + GST Tax for one year courses<br />
                                                                                <span class="webrupee">`</span>30000 + GST Tax for two year courses<br /></td>
                                                                            <td align="left" colspan="2" valign="top"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" valign="top" colspan="2"><strong>3rd instalment </strong> : (b/w1stSep. & 15thFeb. aftercommencement of the course)</td>
                                                                            <td align="left" colspan="3" valign="top"><strong>At the end of 2nd month</strong><br />
                                                                                <span class="webrupee">`</span>15000 + GST Tax for one year courses<br />
                                                                                <span class="webrupee">`</span>28000 + GST Tax for two year courses<br /></td>
                                                                            <td align="left" colspan="2" valign="top"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="7">Cheque / DD dishonoured by the bank will be charged at 500/ - per presentation, No candidate will be eligible to attend the course or the examination on non - payment of fees (* GST as applicable for autonomous courses).</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* <span class="text-center d-block"><small>*Fees & instalments paid will be non-refundable</small></span> */}
                                                        </div>

                                                        <div class="form_block pl-0">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <h6>DECLARATION:</h6>
                                                                    <div className="checkbox_container">
                                                                    <div className="form-group checkbox-group">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="custom-checkbox">
                                                                                    <input defaultChecked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} type="checkbox" name="all_tc" id="all_tc" />
                                                                                    <label htmlFor="all_tc">
                                                                                    I declare that the particulars given by me in this application are correct. If any of the particulars given or documents submitted by me are not 
                                                                                    correct or false, my provisional admission will stand automatically cancelled without any intimation, notice or refund to me and I shall also be liable for any other punishment, penalty or legal action in such cases, I have clearly read and understood the fee structure and rules and regulations for the course given in the course brochure. I fully understand that fees and installment paid will be non-refundables 
                                                                                    I fully understand that P. D. Hinduja Hospital & Medical Research Centre (HNH) is affiliated to Maharashtra State Board of Skill Vocational, Education & Training (MSBSVET) for listed courses. HNH has Life Supporter's Institute of Health Sciences (LIHS) as its administrative agency for running Allied Health Courses.
                                                                                    <br />
                                                                                    However, I also understand that P. D. Hinduja National Hospital & Medical Research Centre (HNH) and LIHS are not affiliated to any University, University Grants Commission, MCI, NCI or AICTE & this course is not recognized by any statutory agency. The course completion certificate shall be issued by HNH, MSBSVET* on successful completion of all the requirements of the course and at sole discretion of HNH, & MSBSVET*.
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    <span class="d-block"><small>*for affiliated courses only</small></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form_block pl-0">
                                                            <APIButton onBtnClick={(e) => handleSubmit(e)} title={`Submit`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                    </>
                )}
            </Layout>
        </>
    )
}
export default OnlineAdmissionForm
